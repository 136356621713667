<template>
  <div class="component">
    <van-action-sheet v-model="show" title="选择员工">
      <div class="content" style="color: #818181;">

        <div style="padding: 0 16px;">
          全部（{{user.length}}）
        </div>

        <van-search @clear="clear" style="padding: 5px 16px" v-model="serchValue" placeholder="请输入搜索关键词" @search="search" />

        <div class="user-list">
          <van-checkbox-group v-if="user.length > 0" icon-size="15px" v-model="radio">
            <div v-for="(item,index) in user" :key="index" :class="{'active-background':  radio == item.id }" class="user" style="padding: 10px 16px;">
              <div>
                <img :src="item.avatar">
                {{ item.name }}
              </div>
              <van-checkbox ref="choseRadio" shape="square" :name="item"></van-checkbox>
            </div>
          </van-checkbox-group >
          <van-empty
              v-else
              class="custom-image"
              :image="require('../../../assets/images/mobiletodo.png')"
              description="暂无员工"
          />
        </div>
        <van-button @click="determine" block style="width: 90%;margin: 0 auto;margin-top: 10px;margin-bottom: 10px" type="info">确定</van-button>

      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import {employeeIndex} from '../../../api/channelCode'
export default {
  data() {
    return {
      show:false,
      radio: [],
      // 搜索名称
      serchValue: '',
      user:[],
      ra: false,
    }
  },
  watch: {
    radio(){
      if(this.ra){
        if(this.radio.length > 1){
          this.radio[0] = this.radio[this.radio.length - 1]
          this.radio.splice(1)
        }
      }
    }
  },
  methods: {
    $show(d,e){
      this.show = true
      this.dataName = e
      // this.radio = d
      if(e == 'max'){
        this.ra = true
      }
      if(d.length <= 0 || this.user.length <= 0 && d.length > 0 ) {
        employeeIndex().then(res=>{
          this.user = res.data
          if(d.length > 0){
            this.editCode(d)
          }
        })
      }else{
        this.editCode(d)
      }
    },
    removeArr(arr) {
      const res = new Map();
      return arr.filter((arr) => !res.has(arr.id) && res.set(arr.id, 1))
    },
    determine (e) {
      if (!this.radio) {
        Toast.fail('请选择客户');
      }
      let data = this.removeArr(this.radio)
      const info = {
        name: this.dataName,
        list: this.dataList
      }
      this.$emit("change",data,info)
      this.show = false
      this.radio = []
    },
    clear(){
      employeeIndex().then(res=>{
        this.user = res.data
        this.radio = this.radio
      })
    },
    search(e){
      employeeIndex({name: e}).then(res=>{
        this.user = res.data
        this.radio = this.radio
      })
    },

    editCode(data){
        this.$nextTick(() => {
          data.forEach((item,index)=>{
            this.user.forEach((its,ins)=>{
              if(item.id == its.id){
                this.$refs.choseRadio[ins].toggle(true)
              }
            })
          })
        })
    }

  }
}
</script>

<style lang="less" scoped>
/deep/ .van-empty__image {
  width: 129px !important;
  height: 115px !important;
}
/deep/.van-action-sheet__header{
  font-weight: bolder;
}
.component {
  .content{
    padding: 0;
    .user-list{
      height: 275px;
      overflow: auto;
      .user{
        display: flex;
        align-items: center;
        justify-content: space-between;
        div{
          font-size: 14px;
          color: #252528;
          img{
            width: 46px;
            height: 46px;
            margin-right: 6px;
          }
        }
      }
      .active-background{
        background: #f6fbff !important;
      }
    }
  }
}
</style>
