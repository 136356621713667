<template>
  <div class="component">
    <van-popup v-model="show" round  position="bottom" :style="{ height: '50%' }">
      <van-datetime-picker
          v-model="currentDate"
          type="time"
          :title="showTime"
          :min-minute="minute"
          :min-hour="hour"
          @cancel="show = false"
          @confirm="choseDate"
      />
    </van-popup>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      currentDate: '',
      date: new Date(),
      index: "",
      // showtime
      showTime: '自定义上线时间',
      minute: null,
      hour: null,
      begin: ''
    }
  },
  watch: {},
  methods: {
    $show(i,e){
      this.show = true
      this.index = i
      this.name = e
      this.minute = null
      this.hour = null
      this.showTime='自定义上线时间'
    },
    choseDate(e) {
      if (typeof this.minute != 'number') {
        this.minute = Number(e.split(":")[1])
        this.hour = Number(e.split(":")[0])
        this.begin = e
        this.showTime = "自定义下线时间"
      } else {
        const dateTime =  {
          time: this.begin + "-" + e,
          index:this.index,
          name: this.name
        }
        this.show = false
        this.$emit('change',dateTime)
      }
    }
  }
}
</script>

<style lang="less" scoped>
/deep/.van-action-sheet__header{
  font-weight: bolder;
}
.component {
}
</style>
