import request from "../plugins/axios";

export function employeeIndex(params) {
    return request({
        url: '/workEmployee/employeeIndex',
        method: 'GET',
        params
    })
}
export function channelCodeGroup(params) {
    return request({
        url: '/channelCodeGroup/index',
        method: 'GET',
        params
    })
}
export function store(data) {
    return request({
        url: '/channelCodeGroup/store',
        method: 'POST',
        data
    })
}
export function contactTagList(params) {
    return request({
        url: '/workContactTag/contactTagList',
        method: 'GET',
        params
    })
}

export function channelCode(data) {
    return request({
        url: '/channelCode/store',
        method: 'POST',
        data
    })
}
export function channelCodeIndex(params) {
    return request({
        url: '/channelCode/index',
        method: 'GET',
        params
    })
}
export function channelCodeRemove(data) {
    return request({
        url: '/channelCode/destroy',
        method: 'DELETE',
        data
    })
}
export function channelCodeShow(params) {
    return request({
        url: '/channelCode/show',
        method: 'GET',
        params
    })
}
export function channelCodeUpdate(data) {
    return request({
        url: '/channelCode/update',
        method: 'PUT',
        data
    })
}
